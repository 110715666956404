import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { partnerships, activities, trainings } from "../data"


export default function ActivitiesPage() {
    return(
        <Layout>
            <SEO title = "Activities"/>
            <section>
                <div id = "partnerships" name = "partnerships" className = "font-montserrat text-4xl text-red-dark">Partnerships</div>
                <div className = "font-montserrat text-red-dark text-2xl">at regional, national, European level, as member of:</div>
                <br/>{
                partnerships.map((partnership) => (
                    <div key = {partnership.title} className = "font-lora">
                        <div className = "text-xl font-bold">&gt; {partnership.title + " | " + partnership.year}</div>
                    </div>
                ))
                }
            </section>
            <br/>
            <section>
                <div id = "valorizing-women-competences" name = "valorizing-women-competences" className = "font-montserrat text-4xl text-red-dark">Valorizing Women{"'s"} Competences</div>
                <br/>
                <div>
                    {
                    activities.map((activity) => (
                        <div key = {activity.name} className = "font-lora">
                            <div className = "text-red-dark font-light text-xl font-bold">&gt; {activity.projects.length > 0  ? activity.name + ":" : activity.name}</div>
                            <ul className = "text-lg list-inside list-disc pl-4">
                            {activity.projects.map((project) => (
                                <li key = {project}>{project}</li>
                            ))}
                            </ul>
                        </div>
                    ))
                    }
                </div>
            </section>
            <br/>
            <section>
                <div id = "posdru" name = "posdru" className = "font-montserrat text-4xl text-red-dark">Professional Training Via Posdru Programme</div>   
                <div id = "local-partner" name = "local-partner" className = "font-montserrat text-3xl text-red-dark">local partners in:</div>  
                <br/> 
                <div>{
                    trainings.map((training) => (
                        <div key = {training.title} className = "font-lora">
                            <div className = "text-2xl font-bold text-red-dark">{training.title + " | " + training.number}</div>
                            <div className = "text-xl">{"Beneficiary: " + training.beneficiary}</div>
                            <div className = "text-xl">{"Local Partner: " + training.localPartner}</div>
                            <div>
                                <div className = "text-lg font-bold">Objectivies:</div>
                                <ul className = "text-lg list-inside list-disc pl-4">
                                    {
                                        training.objectivies.map((objective) => (
                                            <li key = {objective}>{objective}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    ))
                }
                </div>
            </section>
        </Layout>
    )
}